import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitleBack, setPageTitleSubTitle, setPageTitleTitle } from "../../../../store/NavStore/title"

const ProfileSettings = () => {
    const dispatch = useDispatch()

    const user = useSelector((state) => state.auth.user)

    useEffect(() => {
        dispatch(setPageTitleTitle('Настройки'))
        dispatch(setPageTitleSubTitle('Профиль'))
        dispatch(setPageTitleBack({name: 'Настройки', link: `/app/settings`}))
    }, [dispatch])

    console.log(user)

    return (
        <>Скоро</>
    )
}

export default ProfileSettings