import { createSlice } from '@reduxjs/toolkit'

const defParams = {
    pfActive: true,
    psName: true,
    view: true,
    brands: [],
    pagination: {page: 1, pages: 0},
    load: true,
    pfPricer: false
}

export const productsParams = createSlice({
    name: 'productsParams',
    initialState: {
        oz: defParams,
        wb: defParams,
        prods: defParams
    },
    reducers: {
        setOzParams: (state, action) => {
            state.oz = action.payload
        },
        setWBParams: (state, action) => {
            state.wb = action.payload
        },
        setProdsParams: (state, action) => {
            state.prods = action.payload
        }
    }
})

export const { setOzParams, setWBParams, setProdsParams } = productsParams.actions

export const setProductsParam = (code, param, value) => (dispatch, getState) => {
    let params = {...getState().productsParams[code]}
    params[param] = value
    switch (code) {
        case "oz":
            dispatch(setOzParams(params))
            break
        case "wb":
            console.log(params)
            dispatch(setWBParams(params))
            break
        case "prods":
            dispatch(setProdsParams(params))
            break
        default:
            break
    }
}


export default productsParams.reducer