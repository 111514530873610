import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const tax = createSlice({
    name: 'tax',
    initialState: {
        taxes: [],
        companyTaxes: []
    },
    reducers: {
        setTaxes: (state, action) => {
            state.taxes = action.payload
        },
        setCompanyTaxes: (state, action) => {
            state.companyTaxes = action.payload
        }
    }
})

export const { setTaxes, setCompanyTaxes } = tax.actions

export const getTaxes = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/taxes`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setTaxes(response.data.result.taxes))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getCompanyTaxes = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/taxes`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.taxes !== 'undefined') {
                dispatch(setCompanyTaxes(response.data.result.taxes))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const setCompanyTaxesStore = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/taxes`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(getCompanyTaxes())
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default tax.reducer