import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const brand = createSlice({
    name: 'brand',
    initialState: {
        brand: null,
        margin: null,
        history: [],
        removeRes: null
    },
    reducers: {
        setBrand: (state, action) => {
            state.brand = action.payload
        },
        setBrandMargin: (state, action) => {
            state.margin = action.payload
        },
        setBrandMarginHistory: (state, action) => {
            state.history = action.payload
        },
        setRemoveRes: (state, action) => {
            state.removeRes = action.payload
        },
    }
})

export const { setBrand, setBrandMargin, setBrandMarginHistory, setRemoveRes, setSearchFindBrands } = brand.actions

export const getBrand = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/brands/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setBrand(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getBrandMargin = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/brands/${id}/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setRemoveRes(null))
            dispatch(setBrandMargin(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getBrandMarginArchive = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/settings/brands/${id}/margin/archive`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            dispatch(setBrandMarginHistory(response.data.result.margins))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const setMarginBrand = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/settings/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(getBrandMargin(data.brandID))
            dispatch(getBrandMarginArchive(data.brandID))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const updateMarginBrand = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/settings/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(getBrandMargin(data.brandID))
            dispatch(getBrandMarginArchive(data.brandID))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const deleteMarginBrand = (data, brandID) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'delete',
        url: `${gatewayURL}/api/v1/settings/margin`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(setRemoveRes("ok"))
            dispatch(getBrandMargin(brandID))
            dispatch(getBrandMarginArchive(brandID))
        })
        .catch((error) => {
            let msg = error.response.data.result ?? "Error"
            const t = new Date()
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default brand.reducer