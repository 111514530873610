import { useDispatch, useSelector } from "react-redux"
import "./style.css"
import { useEffect } from "react"
import { clearMessagesList } from "../../store/System/messages"

const Messages = () => {

    const messages = useSelector((state) => state.messages.list)
    
    if (!messages || messages.length === 0) {
        return null
    }

    return (
        <div className="message-list">
            {messages.map((e, i) => <Message key={i} data={e} />)}
        </div>
    )
}

export default Messages

const Message = ({data}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        const t = new Date()
        if (t.getTime() - data.time > 10000) {
            dispatch(clearMessagesList(data))
        } else {
            setTimeout(() => {
                dispatch(clearMessagesList(data))
            }, 10000)
        }
    }, [data, dispatch])

    return (
        <div className="message-item">
            {data.message}
        </div>
    )
}