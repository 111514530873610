import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
axios.defaults.withCredentials = true;

export const services = createSlice({
    name: 'services',
    initialState: {
        list: [],
        pricer: null,
        productPricers: null,
        pricerParams: null,
        pricerParamProductWB: null,
        pricerParamProductOZ: null,
        pricerParamProductYM: null,
        counterProduct: null
    },
    reducers: {
        setServicesList: (state, action) => {
            state.list = action.payload
        },
        setPricer: (state, action) => {
            state.pricer = action.payload
        },
        setProductPricers: (state, action) => {
            state.productPricers = action.payload
        },
        setPricerParams: (state, action) => {
            state.pricerParams = action.payload
        },
        setPricerParamWB: (state, action) => {
            state.pricerParamProductWB = action.payload
        },
        setPricerParamOZ: (state, action) => {
            state.pricerParamProductOZ = action.payload
        },
        setPricerParamYM: (state, action) => {
            state.pricerParamProductYM = action.payload
        },
        setCounterProduct: (state, action) => {
            state.counterProduct = action.payload
        }
    }
})

export const { setServicesList, setPricer, setProductPricers, setPricerParams, setPricerParamWB, setPricerParamOZ, setPricerParamYM, setCounterProduct } = services.actions

export const getServices = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/services`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.services !== 'undefined') {
                dispatch(setServicesList(response.data.result.services))
            } else {
                dispatch(setServicesList([]))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getPricerData = (code) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/services/pricer`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.settings !== 'undefined') {

                code = code.substr(1)
                let find = response.data.result.settings.findIndex(e => e.shopCode === code)

                if (find > -1) {
                    dispatch(setPricer(response.data.result.settings[find]))
                }
            } else {
                dispatch(setPricer(null))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getProductPricerData = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/services/pricer`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.settings !== 'undefined') {
                dispatch(setProductPricers(response.data.result.settings))
            } else {
                dispatch(setProductPricers(null))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getPricerParams = (code) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/services/pricer/params?code=${code}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result.params !== 'undefined') {
                dispatch(setPricerParams(response.data.result.params))
            } else {
                dispatch(setPricerParams([]))
            }
        })
        .catch((error) => {
            dispatch(setPricerParams([]))
        });
}

export const getPricerProductParam = (code, productID) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/services/pricer/params?code=${code}&productID=${productID}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            switch (code) {
                case "wb":
                    dispatch(setPricerParamWB(response.data.result))
                    break
                case "oz":
                    dispatch(setPricerParamOZ(response.data.result))
                    break
                case "ym":
                    dispatch(setPricerParamYM(response.data.result))
                    break
                default:
                    console.log("")
                    break
            }
        })
        .catch((error) => {
            switch (code) {
                case "wb":
                    dispatch(setPricerParamWB(null))
                    break
                case "oz":
                    dispatch(setPricerParamOZ(null))
                    break
                case "ym":
                    dispatch(setPricerParamYM(null))
                    break
                default:
                    console.log("")
                    break
            }
        });
}

export const updatePricerParams = (data, code) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/services/pricer/params`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getPricerParams(code))
        })
        .catch((error) => {
            dispatch(updateMessagesList(
                {
                    message: 'Message', type: "error", time: t.getTime()
                }
            ))
        });
}

export const updatePricerProductParams = (data, code, productID) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/services/pricer/params`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getPricerProductParam(code, productID))
        })
        .catch((error) => {
            dispatch(updateMessagesList(
                {
                    message: 'Message', type: "error", time: t.getTime()
                }
            ))
        });
}

export const getCounterProduct = (productID) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/services/counter/params?productID=${productID}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (typeof response.data.result !== 'undefined') {
                dispatch(setCounterProduct(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export const sendCounterProduct = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/services/counter/params`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(getCounterProduct(data.productID))
        })
        .catch((error) => {
            console.log(error);
        });
}

export default services.reducer